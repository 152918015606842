<script setup>
import collect from "collect.js";

const props = defineProps({
  label: {
    default: '',
    type: String
  },
  readonly: {
    default: false,
    type: Boolean
  },
  transparent: {
    default: false,
    type: Boolean
  }
})

const storeConfig = useStoreConfig()
const isShowRegionSelector = ref(false)
const {isRegionForceSelection, isRegionSelectionVisible} = storeToRefs(storeConfig)

const appCountry = useCookie('appCountry')
const appCity = useCookie('appCity')
const appDistrict = useCookie('appDistrict')
const appTown = useCookie('appTown')

const appCityName = useState('appCityName')
const appDistrictName = useState('appDistrictName')
const appTownName = useState('appTownName')

const {countries, cities, districts, towns, getCountries, getCities, getDistricts, getTowns} = useRegion()

// TODO : warehouse id seçimi
const selectedWarehouse = useState("selectedWarehouse")
const showRegionSelector = async () => {
  if (!props.readonly) isShowRegionSelector.value = true
}

const setWarehouseViaTown = (value) => {
  const warehouseId = collect(towns.value).where('name', value).pluck('warehouse_id').first()
  if (warehouseId) selectedWarehouse.value = warehouseId;
}

onNuxtReady(async () => {

  appCountry.value = 'TR';
  await getCities('TR')

  if (appCity.value) {
    await getDistricts(appCity.value)
    appCityName.value = collect(cities.value).where('id', appCity.value).pluck('name').first()
  }

  if (appDistrict.value) {
    await getTowns(appCity.value, appDistrict.value)
    appTownName.value = collect(districts.value).where('district_id', appDistrict.value).pluck('name').first()
  }

  if (appTown.value) {
    setWarehouseViaTown(appTown.value)
    appTownName.value = collect(towns.value).where('town_id', appTown.value).pluck('name').first()

  }

  if (isRegionForceSelection.value && !appCity.value && !appDistrict.value && !appTown.value && !isShowRegionSelector.value) {
    isShowRegionSelector.value = true
  }
})

const saveChanges = () => {
  isShowRegionSelector.value = false
  location.reload()
}

const isDisabled = computed(() => {
  if (towns.value.length > 0 && !appTown.value || districts.value.length > 0 && !appDistrict.value || cities.value.length > 0 && !appCity.value) {
    return true
  } else {
    return false
  }
})

</script>

<template>
  <div class="region-selector--wrapper" :class="[$attrs.class, {'!bg-transparent' : props.transparent}]"
       @click.prevent="showRegionSelector" v-if="isRegionSelectionVisible">
    <LucideMapPin class="region-selector--icon"/>
    <div class="region-selector--text">
      <div class="text-gray-500" v-if="label">{{ label }}</div>
      <div>
        <template v-if="appCityName && appTown">{{ appCityName }} / {{ appTownName }}</template>
        <template v-else-if="appCityName && !appTownName && appDistrictName">{{ appCityName }} / {{ appDistrictName }}</template>
        <template v-else-if="appCityName && !appTownName && !appDistrictName">{{ appCityName }}</template>
        <template v-else>{{ $t('widgets.region-management.select-region') }}</template>
      </div>
    </div>
  </div>

  <FormModal :title="$t('widgets.region-management.title')" width-class="max-w-sm" v-model="isShowRegionSelector" :hide-close="isRegionForceSelection">
    <div class="space-y-4">
      <!--      <FormSelect-->
      <!--          :label="$t('widgets.region-management.country')"-->
      <!--          :options="countries"-->
      <!--          input-value="value"-->
      <!--          input-label="name"-->
      <!--          placeholder="Ülke"-->
      <!--          class="text-base"-->
      <!--          v-model="appCountry"-->
      <!--          @change="getCities"-->
      <!--          name="countryCode"-->
      <!--      />-->

      <FormSelect
          :label="$t('widgets.region-management.city')"
          :options="cities"
          input-value="id"
          input-label="name"
          placeholder="Şehir"
          v-model="appCity"
          @change="getDistricts"
          name="appCity"
          :disabled="cities.length===0"
          filter
      />

      <FormSelect
          :label="$t('widgets.region-management.district')"
          :options="districts"
          input-value="district_id"
          input-label="name"
          :placeholder="$t('widgets.region-management.district')"
          v-model="appDistrict"
          @change="getTowns(appCity, appDistrict)"
          name="appDistrict"
          :disabled="districts.length===0"
          filter
      />

      <FormSelect
          :label="$t('widgets.region-management.town')"
          :options="towns"
          input-value="town_id"
          input-label="name"
          :placeholder="$t('widgets.region-management.town')"
          v-model="appTown"
          name="appDistrict"
          @change="setWarehouseViaTown"
          :disabled="towns.length===0"
          filter
      />

      <FormButton color="success" class="w-full" @click="saveChanges()" :disabled="isDisabled">{{ $t('widgets.region-management.save') }}</FormButton>

    </div>
  </FormModal>
</template>

<style>
.region-selector--wrapper {
  @apply flex items-center space-x-2.5 py-2 px-4 border-2 border-transparent bg-gray-100 hover:bg-gray-200 lg:rounded-lg cursor-pointer;
}

.region-selector--icon {
  @apply h-5 w-5 sm:h-4 sm:w-4;
}

.region-selector--text {
  @apply text-sm font-medium leading-4 whitespace-nowrap;
}
</style>